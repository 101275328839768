@import '../../theme/colors.styl';

.button
  border: 2px solid
  display: inline-block
  border-radius: 50vh
  padding: 8px 16px
  font-family: Graphik-Semibold
  font-size: inherit
  line-height: inherit
  min-height: 32px
  min-width: 32px
  cursor: pointer
  &:hover, &:focus
    outline: none

.button--primary
  border-color: colors.secondary;
  background-color: colors.secondary;
  color: colors.white
  &:hover
    background-color: lighten(colors.secondary, 10%)
    border-color: lighten(colors.secondary, 10%)
  &:active
    background-color: darken(colors.secondary, 5%)
    border-color: darken(colors.secondary, 5%)

.button--secondary
  border-color: colors.secondary;
  background-color: transparent;
  color: colors.secondary
  &:hover
    background-color: rgba(
      red(colors.secondary),
      green(colors.secondary),
      blue(colors.secondary),
    .05
    );
    color: lighten(colors.secondary, 10%)
    border-color: lighten(colors.secondary, 10%)
  &:active
    background-color: rgba(
      red(colors.secondary),
      green(colors.secondary),
      blue(colors.secondary),
    .1
    );
    color: darken(colors.secondary, 5%)
    border-color: darken(colors.secondary, 5%)

.button--disabled
  border-color: colors.disabled;
  background-color: colors.disabled;
  color: colors.white
  &:hover, &:focus, &:active
    cursor: not-allowed

