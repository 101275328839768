@import "~@rategravity/frontend/theme/colors.styl"

.funnelAction
  text-align center
  padding 1em 3em

.funnelAction__link
  &:hover
    text-decoration none

.funnelAction__text
    color: colors.primary