@import '../../theme/colors';

.container
  margin .5em
  padding .5em

.container--light
  background-color: colors['green-light'];

.container--dark
  background-color: colors['green-dark'];

.container--grey
  background-color: colors.grey;

