@import '~@rategravity/frontend/theme/colors.styl'

.page__container
  @media only screen and (max-width 860px)
    flex-direction column

.page__content
  background-color colors['grey-light'];

.page__content--full
  height 100%

.page__title
  text-align center
  padding 0 2em

.page__center
  display flex
  flex-direction column
  justify-content center
  height 100%
  align-items center

.page__fullWidth
  width 100%

.sidePanels
  display: flex
  flex-direction row
  justify-content center
  margin-top 2%
  margin-bottom 15em
  @media only screen and (max-width 860px)
    flex-direction column

.halfPanel
  flex 0 1 320px
  margin-left 4em
  margin-right 4em
  margin-bottom 2em
  @media only screen and (max-width 430px)
    margin-left 0
    margin-right 0

.circleText
  display flex
  align-items center
  justify-content flex-start
  margin-top 1em
  margin-bottom 1em
  @media only screen and (max-width 860px)
    flex-direction column
  img
    margin-right 1em
    margin-top 0.5em
  h5
    text-align left
    @media only screen and (max-width 430px)
      text-align center
    margin-top 1em
    margin-bottom 1em

.faked__svgSpacing
  a
    h5
      margin-top 0.5em