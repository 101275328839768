@import '~@rategravity/frontend/theme/colors.styl'

.footer
  margin 0
  padding 2em

.footer__navs,
.footer__socials
  display flex
  justify-content center
  flex-wrap wrap
  opacity 1
  a, a:visited
    opacity: 0.5
    color: colors.primary
    &:hover
      color: colors.secondary
      opacity 1

.footer__nav
  padding .5em 1em
  text-align center
  white-space nowrap

.footer__social
  padding .75em
