@import '../../theme/colors.styl'

.page
  min-height 100vh
  display flex
  flex-direction column

.page__header
  z-index 10

.page__container
  display flex
  flex-grow 1
  background-color colors['grey-light']

.page__content
  flex-grow 1
  background-color: colors.white;
