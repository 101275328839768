@import '~@rategravity/frontend/theme/colors.styl'

.optionPanel
  background-color colors['grey-light'];
  padding 3em 0 15em
  color colors['primary'];
  max-width 60%;
  margin 0 auto;
  @media (max-width: 720px)
    max-width 100%
    padding 0 24px;

.optionpanel__startNow
  padding 64px 0 0;
  @media (max-width: 720px)
    padding 0 24px

.optionPanel__title
  font-size 32px
  text-align center
  padding 24px 0
  margin 0 auto
  max-width 960px

.optionPanel__subTitle
  font-weight bold
  font-size: 16px
  text-align center
  padding 1em 1em 0em
  margin 0 auto
  max-width 960px

.optionPanel__beforeText
  max-width: 600px;
  padding 0 22px
  margin 24px auto 0px
  font-size 14px
  text-align center

.optionPanel__afterText
  max-width: 600px;
  border-top 0.5px solid #B2C1C0 
  border-bottom 0.5px solid #B2C1C0 
  padding 32px 22px
  margin 64px auto
  font-size 12px
  line-height 16px
  text-align center

.optionPanel__options
  margin-top 2em
  display flex
  justify-content center
  flex-direction row
  @media (max-width: 720px)
    margin-top 1em
    flex-direction column
    align-items center

.optionPanel--quad
  .optionPanel__options
    @media (max-width: 1080px)
      flex-direction column
      align-items center

.optionPanel__options--quad
  display flex
  @media (max-width: 720px)
    flex-direction column
    align-items center

.optionPanel.optionPanel__link .optionPanel__options
  flex-direction column



