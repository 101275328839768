.pitch
  display flex
  flex-direction column
  width 80%
  max-width 500px
  justify-content space-between
  align-items center
  background-color inherit

.pitch__link
  margin-top 0.5em
  padding 0
  width 80%
  max-width 480px

.pitch__button
  border-radius 24px
  width 94%
