@font-face {
  font-family: 'Graphik-Semibold';
  src: url('./webfonts/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
    url('./webfonts/Graphik-Semibold.otf') format('opentype'),
    url('./webfonts/Graphik-Semibold.eot'), url('./webfonts/Graphik-Semibold.woff2') format('woff2'),
    url('./webfonts/Graphik-Semibold.woff') format('woff'),
    url('./webfonts/Graphik-Semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-Regular';
  src: url('./webfonts/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
    url('./webfonts/Graphik-Regular.otf') format('opentype'), url('./webfonts/Graphik-Regular.eot'),
    url('./webfonts/Graphik-Regular.woff2') format('woff2'),
    url('./webfonts/Graphik-Regular.woff') format('woff'),
    url('./webfonts/Graphik-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-Medium';
  src: url('./webfonts/Graphik-Medium.otf') format('opentype'),
    url('./webfonts/Graphik-Medium.woff2') format('woff2'),
    url('./webfonts/Graphik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monosten-Bold';
  src: url('./webfonts/Monosten-Bold.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monosten-Light';
  src: url('./webfonts/Monosten-Light.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monosten-Medium';
  src: url('./webfonts/Monosten-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TiemposHeadline-Medium';
  src: url('./webfonts/TiemposHeadline-Medium.eot?#iefix') format('embedded-opentype'),
    url('./webfonts/TiemposHeadline-Medium.otf') format('opentype'),
    url('./webfonts/TiemposHeadline-Medium.eot'),
    url('./webfonts/TiemposHeadline-Medium.woff2') format('woff2'),
    url('./webfonts/TiemposHeadline-Medium.woff') format('woff'),
    url('./webfonts/TiemposHeadline-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}